<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="6" :md="3" :lg="3">
        <Select
          v-model="query.rentType"
          size="small"
          placeholder="招租方式"
          clearable
        >
          <Option
            v-for="(item, index) in rentTypeArray"
            :key="index"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="6" :md="3" :lg="3" class="m-b-5">
        <DatePicker size="small" transfer :show-week-numbers="true" type="daterange" v-model="orderSchdule"
          @on-change="scheduleChange" style="width: 100%" placement="top-start" placeholder="租赁开始时间"></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="6" :md="3" :lg="3" class="m-b-5">
        <Select
          v-model="query.templateId"
          size="small"
          placeholder="合同模板"
          clearable
        >
          <Option
            v-for="(item, index) in templateArray"
            :key="index"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>

      <i-col :xs="24" :sm="6" :md="3" :lg="3" class="m-b-5">
        <Select
          v-model="query.status"
          size="small"
          placeholder="状态"
          clearable
        >
          <Option
            v-for="(item, index) in statusArray"
            :key="index"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="6" :md="3" :lg="3" class="m-b-5">
        <i-input
          size="small"
          v-model="query.queryString"
          placeholder="关键字"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="6" :md="3" :lg="3" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        >
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="6" class="m-b-5">
        <a style="float: right; line-height: 30px" @click="handleCreateInvestment"
          >+合同创建</a
        >
      </i-col>
    </Row>
    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
<CreateContract ref="CreateContractModal"></CreateContract>
    <!-- <Modal v-model="investmentCreateModal" width="1200">
      <p slot="header" class="text-center">发起招商</p>
      <CreateInvestment v-if="investmentCreateModal" :rentTypeArray="rentTypeArray" :templateArray="templateArray" ref="investmentCreate"  :onSuccess="initPageData"></CreateInvestment>
      <div slot="footer">
        <Button type="text" class="m-r-5" @click="investmentCreateModal = false">取消</Button>
        <Button type="primary" @click="handleCreateInvestemnt">确定</Button>
      </div>
    </Modal> -->

  </div>
</template>

<script>

import CreateContract from './modalComponents/CreateContract.vue'

import { offProject } from '@/api/invest/invest'
import { listContractInfo } from '@/api/invest/contract'

import { GetDateStr } from '@/utils/dateFormat'

export default {
  components: {
    CreateContract
  },
  data () {
    return {
      rentTypeArray: [],
      statusArray: [],
      templateArray: [],
      investmentCreateModal: false,

      orderSchdule: [],
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        queryString: ''
      },
      tableData: [],
      columnManagedata: [
        {
          title: '合同编号',
          key: 'code'
        },
        {
          title: '合同名称',
          key: 'name'
        },
        {
          title: '来源项目',
          key: 'investName'
        },

        {
          title: '租赁用途',
          key: 'purposeTypeName'
        },
        { title: '甲方', key: 'companyName' },
        { title: '乙方', key: 'advertiserName' },
        { title: '状态', key: 'contractStatusName' },
        {
          title: '操作',
          align: 'center',
          width: 120,
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$store.commit('set_contract_pageType', 'detail')
                    this.$store.commit('set_contract_id', params.row.id)
                    // this.handleShowDetail(params.row.id)
                  }
                }
              },
              '详情'
            )
            const editButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$store.commit('set_contract_pageType', 'edit')
                    this.$store.commit('set_contract_currentStep', 'basic')
                    this.$store.commit('set_contract_id', params.row.id)
                  }
                }
              },
              '编辑'
            )
            const removeButton = h(
              'a',
              {
                style: {
                  color: 'red',
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: '操作确认？',
                      content:
                        '<p>确定要下架【 ' +
                        params.row.name +
                        '】项目？</p>',
                      loading: true,
                      onOk: () => {
                        offProject({
                          investId: params.row.id
                        })
                          .then((res) => {
                            if (res && !res.errcode) {
                              this.$Notice.success({ desc: '操作成功' })
                              this.initPageData()
                            }
                            this.$Modal.remove()
                          })
                          .catch((error) => {
                            this.$Modal.remove()
                            this.$Notice.error({
                              title: '服务器请求错误',
                              desc: error
                            })
                          })
                      }
                    })
                  }
                }
              },
              '下架'
            )
            const returnBtns = []
            if (params.row.status === 1) {
              // returnBtns.push(publicButton)
              returnBtns.push(editButton)
            }
            if (params.row.status === 3) {
              returnBtns.push(removeButton)
            }

            returnBtns.push(detailButton)
            return h('div', returnBtns)
          }
        }
      ]
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      listContractInfo(this.query).then((response) => {
        this.total = response.totalRow
        this.tableData = response.list
        this.query.pageNumber = response.pageNumber
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.initPageData()
    },

    scheduleChange () {
      const shcedule = this.orderSchdule
      if (shcedule[0] === '' || shcedule[1] === '') {
        this.query.rentStartDate = ''
        this.query.rentEndDate = ''
      } else {
        this.query.rentStartDate = GetDateStr(shcedule[0], 1)
        this.query.rentEndDate = GetDateStr(shcedule[1], 1)
      }
    },

    handleCreateInvestment () {
      // this.$store.commit('set_contract_id', null)
      // this.$store.commit('set_contract_pageType', 'create')
      // this.investmentCreateModal = true
      this.$refs.CreateContractModal.showModal()
    },

    handleCreateInvestemnt () {
      this.$refs.investmentCreate.handleSubmit()
      this.deviceAddModal = false
    }
  },
  watch: {
    beginUpdate () {
      this.initPageData()
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.investmentContract.beginUpdate
    }
  }
}
</script>
